<template>
  <div class="login-container">
    <el-form :model="loginForm" class="login-form" label-position="left">
      <h2 class="title">{{ $settings.title }}</h2>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username"
                  name="username"
                  placeholder="请输入用户名"/>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
            v-model="loginForm.password" name="password"
            placeholder="请输入密码" show-password
            @keyup.enter.native="handleLogin"/>
      </el-form-item>

      <div class="footer">
        <el-button :loading="loading"
                   class="btn-submit" type="primary"
                   @click.native.prevent="handleLogin">
          登陆
        </el-button>

        <div>
          <router-link class="tip" to="/register">没有账户？点击这里注册</router-link>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import Cookie from 'js-cookie'

export default {
  name: 'Login',
  data() {
    return {
      showPasswordText: false,
      loginForm: {
        username: '',
        password: ''
      },
      redirect: undefined,
      loading: false
    }
  },
  watch: {},
  methods: {
    handleLogin() {
      if (!(this.loginForm.username && this.loginForm.password)) {
        return this.$message.error('请输入用户名和密码')
      }
      // this.loading = true


      // if (this.$settings.debug) {
      //   this.loading = false
      //   Cookie.set('UID', 'mock-uid')
      //   Cookie.set(this.$settings.headerToken, 'mock-token')
      //   return this.$router.push({path: this.$route.query.redirect || '/dashboard'})
      // }

      this.$api.account.login(this.loginForm)
          .then(data => {
            this.loading = false
            this.$dbus.account = data
            this.$dbus.uid = data.id
            Cookie.set('UID', data.id)
            Cookie.set(this.$settings.headerToken, data.token)
            this.$router.push({path: this.$settings.home})
          }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;


.login-container {
  background-color: $bg;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;

  .login-form {
    width: 270px;

    .title {
      color: $light_gray;
      margin: 0 auto 30px auto;
      text-align: center;
    }

    .footer {
      text-align: center;

      .tip {
        color: aliceblue;
        cursor: pointer;
      }
    }

  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }


  .btn-submit {
    width: 100%;
  }

}
</style>
